<template>
   <footer class="cart-footer">
      <div class="cart-footer__wrapper">
         <p class="cart-footer__products-count">
            Товаров в корзине: <span>{{ productsCount }}</span>
         </p>

         <p class="cart-footer__total-amount">
            Итого, к оплате: <span>{{ totalAmount }}&nbsp;{{ currency }}</span>
         </p>
      </div>

      <div class="cart-footer__wrap">
         <button
            @click="closeModal"
            class="cart-footer__btn cart-footer__btn--continue-shopping btn"
         >
            Продолжить покупки
         </button>

         <button
            :disabled="!productsCount"
            :title="warning"
            @click="goToOrderPage"
            class="cart-footer__btn cart-footer__btn--place-order btn"
         >
            Оформить заказ
         </button>
      </div>
   </footer>
</template>

<script>
   import { mapState, mapGetters } from 'vuex';

   export default {
      name: 'CartFooter',

      inject: ['closeModal'],

      methods: {
         goToOrderPage() {
            this.closeModal();
            this.$router.push({ name: 'Order' });
         }
      },

      computed: {
         ...mapState('cart', ['products']),
         ...mapGetters('cart', ['productsCount']),

         totalAmount() {
            return this.products.reduce((sum, item) => item.price * item.count + sum, 0);
         },

         warning() {
            return this.productsCount ? null : 'В корзине нет товаров!';
         }
      }
   };
</script>
